import { inject, Injectable, OnDestroy } from '@angular/core';
import {
  APP_CONFIG,
  CompanyAdministratorSubscription,
  ENVIRONMENT_CONFIG,
  MovinmotionAppEnum,
  Rights,
  ServiceActivationCodeEnum,
  User,
  UserFf,
  WorkerMotivationEnum,
  WorkerTypeCodeEnum,
} from '@movinmotion/conf-shared';
import { DecorateAll, distinctUntilObjChanged } from '@movinmotion/util-functions';
import * as mixpanel from 'mixpanel-browser';
import { Dict, Mixpanel, Query } from 'mixpanel-browser';
import { BehaviorSubject, filter, skipWhile, Subject, takeUntil } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

const movinmotionMail = '@movinmotion.com';

function checkMixpanelEnabled(_: unknown, __: string | symbol, descriptor: PropertyDescriptor) {
  const original = descriptor.value;
  descriptor.value = function (...args: unknown[]) {
    if (
      (this as MixpanelService).environment.thirdParty.mixpanel?.enabled &&
      (this as MixpanelService).environment.thirdParty.mixpanel?.token
    ) {
      return original.apply(this, args);
    }
  };
}

type MixpanelOrigin = 'MOVINMOTION' | 'FILM_FRANCE';

type RoleCode = 'SUPER' | 'CUSTOM' | 'MINIMAL' | '';

type ProductName = 'TALENTS' | 'FFBO' | 'SALARIÉ' | 'ENTREPRISE' | 'AUTH';

export type MixpanelUser = User | UserFf;

export type MixpanelTrackingType = 'default' | 'link' | 'form';

interface TrackInfos {
  eventName: string;
  data?: Record<string, unknown>;
}

interface TrackDefault extends TrackInfos {
  trackingType?: Extract<MixpanelTrackingType, 'default'>;
  sendImmediately?: boolean;
  callback?: (res: mixpanel.Response) => void;
}

interface TrackQueryIdItems extends TrackInfos {
  trackingType: Exclude<MixpanelTrackingType, 'default'>;
  elementQueryId: Query;
}

type Track = TrackDefault | TrackQueryIdItems;

interface UserData {
  user: MixpanelUser;
  origin: MixpanelOrigin;
}

interface CompanyData {
  company: CompanyAdministratorSubscription;
  rights: Rights;
}

type UserModule = 'talents' | 'situation' | 'activities' | 'nda' | 'compta';

@Injectable()
@DecorateAll(checkMixpanelEnabled)
export class MixpanelService implements OnDestroy {
  environment = inject(ENVIRONMENT_CONFIG);

  hasUserOptIn$ = new BehaviorSubject<boolean>(false);

  private appConfig = inject(APP_CONFIG);
  private router = inject(Router);

  private isInit = false;

  private lastUserData$ = new BehaviorSubject<UserData | null>(null);
  private lastCompanyRightsData$ = new BehaviorSubject<CompanyData['rights'] | null>(null);

  private destroy$ = new Subject<void>();

  constructor() {
    this.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  init(): void {
    if (!this.isInit) {
      if (this.environment.thirdParty.mixpanel?.token) {
        mixpanel.init(this.environment.thirdParty.mixpanel?.token, {
          debug: true,
          track_pageview: false,
          persistence: 'localStorage',
          api_host: 'https://api-eu.mixpanel.com',
          loaded: () => {
            this.isInit = true;
            this.initListeners();
            this.registerAppRelatedProperties();
          },
          track_links_timeout: 5000,
        });
      }
    }
  }

  onLogout(): void {
    this.reset();
  }

  onUserChange({ user, origin = 'MOVINMOTION' }: UserData): void {
    this.lastUserData$.next({ user, origin });
  }

  onCompanyChange({ company, rights }: CompanyData): void {
    if (this.isNotMmUserOnProd()) {
      this.lastCompanyRightsData$.next(rights);

      mixpanel.set_group('companyId', company.companyId);
      mixpanel.get_group('companyId', company.companyId).set({
        $name: company.companyName,
        firstPaymentDate: company.intermittentFirstMonth,
        idcc: company.conventionCollectiveNumber,
        talentsActivated: this.formatServiceActivationCode(company.services.talentsActivationCode),
        comptaActivated: this.formatServiceActivationCode(company.services.comptaActivationCode),
        comptaAnalyticsOnly: company.analyticAxisOnly,
        esignActivated: company.isEsignEnabled,
        ndaActivated: this.formatServiceActivationCode(company.services.ndaActivationCode),
        socialSubscriptionType: company.subscriptionPack.socialSubscriptionTypeCode,
        comptaSubscriptionType: company.subscriptionPack.comptaSubscriptionTypeCode,
        talentsSubscriptionType: company.subscriptionPack.talentSubscriptionTypeCode,
        comptaSubscriptionDate: company.comptaSubscriptionDate,
        talentsSubscriptionDate: company.talentsSubscriptionDate,
      });

      if (this.hasUserOptIn$.value) {
        this.registerRole(rights);
      }
    }
  }

  trackPageView(): void {
    if (this.isNotMmUserOnProd()) {
      (mixpanel as unknown as Mixpanel).track_pageview();
    }
  }

  track(trackInfos: Track): void {
    if (this.isNotMmUserOnProd()) {
      switch (trackInfos.trackingType) {
        case 'form':
          mixpanel.track_forms(trackInfos.elementQueryId, trackInfos.eventName, trackInfos.data);
          break;
        case 'link':
          mixpanel.track_links(trackInfos.elementQueryId, trackInfos.eventName, trackInfos.data);
          break;
        case 'default':
        default:
          mixpanel.track(
            trackInfos.eventName,
            trackInfos.data,
            { send_immediately: trackInfos.sendImmediately ?? false },
            res => {
              trackInfos.callback?.(res);
            },
          );
          break;
      }
    }
  }

  getProductName(): ProductName {
    switch (this.appConfig.appId) {
      case MovinmotionAppEnum.TALENT_BO:
        return 'FFBO';
      case MovinmotionAppEnum.TALENTS:
        return 'TALENTS';
      case MovinmotionAppEnum.WORKER:
        return 'SALARIÉ';
      case MovinmotionAppEnum.CORE:
        return 'ENTREPRISE';
      case 'AUTH':
        return 'AUTH';
      default:
        throw new Error('Mixpanel is not implemented yet on this service');
    }
  }

  private onUserDataChange(): void {
    this.lastUserData$
      .pipe(
        takeUntil(this.destroy$),
        skipWhile(u => u === null),
        distinctUntilObjChanged(),
      )
      .subscribe(user => {
        this.updateUser(user);
      });
  }

  private updateUser(userData: UserData | null): void {
    if (this.hasUserOptIn$.value && userData) {
      const userId =
        userData?.origin === 'FILM_FRANCE'
          ? this.formatUserFfId((userData?.user as UserFf).id)
          : (userData?.user as User).id.toString();
      this.identify(userId);
    } else {
      mixpanel.identify();
    }
    if (userData?.user) {
      this.updateUserProfile(userData as UserData);
    }
  }

  private identify(userId: string): void {
    if (this.isNotMmUserOnProd()) {
      mixpanel.identify(userId.toString());
    }
  }

  private computeCustomModules(user: User): UserModule[] {
    const noModulesEnabled = !user.profile.typesCodes?.length && !user.profile.workerMotivations?.length;

    const activitiesWorkerTypes = [
      WorkerTypeCodeEnum.PERMANENT,
      WorkerTypeCodeEnum.STRINGER,
      WorkerTypeCodeEnum.EXTRA,
      WorkerTypeCodeEnum.OTHER,
    ];
    const modules: UserModule[] = [];
    if (user.settings.mmTalentsEnabled || user.profile.workerMotivations.includes(WorkerMotivationEnum.FIND_JOB)) {
      modules.push('talents');
    }
    if (
      user.settings.poleEmploiRightsEnabled ||
      (noModulesEnabled &&
        (user.profile.typesCodes.includes(WorkerTypeCodeEnum.INTERMITTENT) ||
          user.profile.workerMotivations.includes(WorkerMotivationEnum.RIGHTS)))
    ) {
      modules.push('situation');
    }
    if (
      user.settings.activityDocumentsEnabled ||
      user.profile.typesCodes.some(userType => activitiesWorkerTypes.includes(userType))
    ) {
      modules.push('activities');
    }
    if (user.settings.ndaEnabled && user.profile.typesCodes.includes(WorkerTypeCodeEnum.AUTHOR)) {
      modules.push('nda');
    }
    if (user.settings.comptaEnabled) {
      modules.push('compta');
    }
    return modules;
  }

  private updateUserProfile({ user, origin = 'MOVINMOTION' }: UserData): void {
    let data: Dict = {
      origin,
    };

    if (origin === 'MOVINMOTION') {
      data = {
        ...data,
        typesCodes: (user as User).profile.typesCodes,
        workerMotivations: (user as User).profile.workerMotivations,
        customModules: this.computeCustomModules(user as User),
      };
    }

    if (this.hasUserOptIn$.value) {
      if (origin === 'FILM_FRANCE') {
        // Can't define activation_date on filmfrance user
        data = {
          ...data,
          $first_name: (user as UserFf).firstname,
          $last_name: (user as UserFf).lastname,
          $email: (user as UserFf).mail,
        };
      } else {
        data = {
          ...data,
          $first_name: (user as User).profile.firstname,
          $last_name: (user as User).profile.lastname,
          activationDate: (user as User).activationDate,
          $email: (user as User).profile.mail,
        };
      }
    }

    mixpanel.people.set(data);
  }

  private reset(): void {
    mixpanel.reset();
    this.registerAppRelatedProperties();
  }

  private registerAppRelatedProperties(): void {
    mixpanel.register({
      productName: this.getProductName(),
    });
  }

  private formatUserFfId(userId: UserFf['id']): string {
    return `${userId}-ff`;
  }

  private formatServiceActivationCode(value?: ServiceActivationCodeEnum): boolean {
    return value === ServiceActivationCodeEnum.ACTIVATED;
  }

  private registerRole(rights: CompanyData['rights']): void {
    const roleCode = this.getRoleCode(rights);
    mixpanel.register({
      roleCode,
    });
  }

  private getRoleCode(rights: Rights): RoleCode {
    switch (true) {
      case rights.all:
        return 'SUPER';
      case Object.keys(rights).length > 0:
        return 'CUSTOM';
      default:
        return 'MINIMAL';
    }
  }

  private initListeners(): void {
    this.onUserDataChange();

    this.hasUserOptIn$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) {
        this.restoreData();
      } else {
        this.reset();
      }
    });

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.trackPageView();
      });
  }

  private restoreData(): void {
    if (this.lastUserData$.value) {
      this.updateUser(this.lastUserData$.value);
    }
    if (this.lastCompanyRightsData$.value) {
      this.registerRole(this.lastCompanyRightsData$.value);
    }
  }

  private isNotMmUserOnProd(): boolean {
    const mixpanelConfig = this.environment.thirdParty.mixpanel;
    const user = this.lastUserData$.value?.user;
    if (!user) {
      return true;
    }
    const isProdEnv = mixpanelConfig?.name === 'MM prod';
    const mailMM =
      (user as User)?.profile?.mail?.endsWith(movinmotionMail) || (user as UserFf)?.mail?.endsWith(movinmotionMail);
    return !(isProdEnv && mailMM);
  }
}
